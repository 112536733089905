import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const FormTextArea = ({
  type,
  label,
  placeholder,
  name,
  value,
  required,
  errors,
  onChange
}) => (
  <div
    className={classnames("form-group", {
      "has-error": errors && errors.length > 0
    })}
  >
    {label ? (
      <label
        className="control-label word-wrap"
        htmlFor={`input${name}`}
      >
        {label}
        {required ? " *" : ""}
      </label>
    ) : null}
    <textarea
      id={`input${name}`}
      cols="5"
      rows="3"
      type={type}
      className="form-control"
      placeholder={placeholder}
      name={name}
      value={value || ""}
      onChange={onChange}
    />
    {errors ? <span className="help-block">{errors.join(", ")}</span> : null}
  </div>
);

FormTextArea.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.array,
  onChange: PropTypes.func
};

FormTextArea.defaultProps = {
  type: "text",
  required: false
};

export default FormTextArea;
