import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const FormCheckBox = ({
  type,
  label,
  name,
  checked,
  errors,
  required,
  onChange,
  value
}) => (
  <div
    className={classnames("checkbox", {
      "has-error": errors && errors.length > 0
    })}
  >
    <label
      htmlFor={`checkbox-${name}-${value}`}
    >
      <input
        id={`checkbox-${name}-${value}`}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      {label}
      {required ? " *" : ""}
    </label>

    {errors ? <span className="help-block">{errors.join(", ")}</span> : null}
  </div>
);

FormCheckBox.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  errors: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

FormCheckBox.defaultProps = {
  required: false
};

export default FormCheckBox;
