import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const FormField = ({
  type,
  label,
  placeholder,
  name,
  value,
  required,
  step,
  min,
  max,
  fieldErrors,
  onChange
}) => (
  <div
    className={classnames("form-group", {
      "has-error": fieldErrors && fieldErrors.length > 0
    })}
  >
    {label ? (
      <label
        className="control-label word-wrap"
        htmlFor={name}
      >
        {label}
        {required ? " *" : ""}
      </label>
    ) : null}
    <input
      id={name}
      type={type}
      className="form-control"
      placeholder={placeholder}
      name={name}
      value={value || ""}
      step={step}
      min={min}
      max={max}
      onChange={onChange}
    />
    {fieldErrors ? <span className="help-block">{fieldErrors.join(", ")}</span> : null}
  </div>
);

FormField.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  fieldErrors: PropTypes.array,
  onChange: PropTypes.func
};

FormField.defaultProps = {
  type: "text",
  required: false
};

export default FormField;
