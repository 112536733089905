import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import lodash from "lodash";
import AsyncSelect from "react-select/async";
import classnames from "classnames";

const buildDataResult = (users, selectedUserIds) => {
  return users.filter(user => !selectedUserIds.includes(user.id)).map(user => {
    const label = user.full_name ? user.full_name : user.name;

    return {
      value: user.id.toString(),
      label: label
    };
  });
};

class FormUsers extends React.Component {
  state = {
    loading: false,
    usersToSelect: [],
    selectedUsers: this.props.value
  };

  componentWillMount() {
    axios.get("/api/v1/employees.json?method=full_name")
      .then((resp) => {
        this.setState({ usersToSelect: resp.data });
      })
    ;
  }

  componentWillReceiveProps(newProps) {
    if(this.props != newProps) {
      this.setState({
        fieldErrors: newProps.fieldErrors
      });
    }
  }

  render() {
    const {
      loading,
      usersToSelect,
      selectedUsers
    } = this.state;

    const {
      formField,
      label,
      formName,
      onChange,
      required,
      value,
      fieldErrors
    } = this.props;

    const usersToSelectFormatted = usersToSelect.map(user => ({
      value: user.id,
      label: user.full_name
    }));

    return (
      <div>
        <div
          className={classnames("form-group", {
            "has-error": fieldErrors && fieldErrors.length > 0
          })}
        >
          {label ? (
            <label
              className="control-label word-wrap"
              htmlFor={`select${name}`}
            >
              {label}
              {required ? " *" : ""}
            </label>
          ) : null}

          <AsyncSelect
            isMulti={true}
            cacheOptions
            className="custom-react-select"
            value={this.state.selectedUsers.filter(user => user._destroy !== true)}
            defaultOptions={usersToSelectFormatted}
            loadOptions={this.loadOptions}
            onChange={this.handlerChangeUsers}
          />

          {fieldErrors ? (
            <span className="help-block">{fieldErrors.join(", ")}</span>
          ) : null}
        </div>

      </div>
    );
  }

  handlerChangeUsers = newUsers => {
    const selectedUsers = this.state.selectedUsers;

    // Add a new user
    newUsers.forEach(user => {
      const idx = lodash.findIndex(selectedUsers, { user_id: user.value });
      if (idx === -1) {
        selectedUsers.push({
          _id: new Date().getTime(),
          user_id: user.value,
          value: user.value,
          label: user.label
        });
      }
    });

    // Remove a new user or remove a selected user
    selectedUsers.forEach((user, index) => {
      const idx = lodash.findIndex(newUsers, { value: user.user_id });
      if (idx === -1) {
        if (user.id) {
          user._destroy = true;
        } else {
          selectedUsers.splice(index, 1);
        }
      }
    });

    this.setState({ selectedUsers: selectedUsers, fieldErrors: {} });
    this.props.onChange(selectedUsers, this.props.associationName);
  };

  loadOptions = inputValue => {
    const urlSearch = "/api/v1/employees.json?search={q}&method=full_name";

    const url = urlSearch.replace("{q}", encodeURIComponent(inputValue));
    return axios.get(url).then(resp => buildDataResult(
      resp.data, this.state.selectedUsers.map(u => u.user_id)
    ));
  };
}

FormUsers.propTypes = {
  users: PropTypes.array,
  fieldErrors: PropTypes.array,
  usersToSelect: PropTypes.array,
  selectedUsers:PropTypes.array
};

export default FormUsers;
