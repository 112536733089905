import React from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import classnames from "classnames";

class FormSelect extends React.Component {
  render() {
    const {
      label,
      dataValue,
      fieldErrors,
      name,
      value,
      required,
      skipSelect,
      options,
      onChange
    } = this.props;

    return (
      <div
        className={classnames("form-group", {
          "has-error": fieldErrors && fieldErrors.length > 0
        })}
      >
        {label ? (
          <label
            className="control-label word-wrap"
            htmlFor={`select${name}`}
          >
            {label}
            {required ? " *" : ""}
          </label>
        ) : null}

        <select
          id={`select${name}`}
          className="form-control"
          name={name}
          value={value}
          onChange={onChange}
        >
          {skipSelect ? null : (
            <option value={value === "--"} value="--">
              Select...
            </option>
          )}
          {options.map(option => (
            <option key={`${option[0]}-${option[1]}`} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </select>

        {fieldErrors ? (
          <span className="help-block">{fieldErrors.join(", ")}</span>
        ) : null}
      </div>
    );
  }

  handleChange = data => {
    const { name, onChange } = this.props;

    if (Array.isArray(data)) {
      onChange(data);
    } else {
      onChange({ target: { value: data.value, name: name } });
    }
  };
}

FormSelect.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  fieldErrors: PropTypes.array,
  dataValue: PropTypes.array,
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  skipSelect: PropTypes.bool,
  onChange: PropTypes.func
};

FormSelect.defaultProps = {
  required: false
};

export default FormSelect;
