import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class FormDateTime extends React.Component {
  
  componentDidMount() {
    let datetimeInput = `#input-${this.props.name.replace(/[|&;$%@"'<>()+,]/g, "")}`;

    switch (this.props.type) {
      case "date":
      case 2:
        $(datetimeInput).kendoDatePicker({
          change: this.props.onChange,
          value: this.props.value ? moment(this.props.value).toDate() : null
        });
        break;
      case "time":
      case 11:
        if(this.props.value && moment(this.props.value).isValid()) {
          var timeValue = moment(this.props.value).toDate();
        } else {
          var timeValue = moment(this.props.value, "hh:mm").toDate();
        };
        $(datetimeInput).kendoTimePicker({
          change: this.props.onChange,
          value: this.props.value ? timeValue : null
        });
        break;
      default:
        $(datetimeInput).kendoDateTimePicker({
          change: this.props.onChange,
          value: this.props.value ? moment(this.props.value).toDate() : null
        });
    }

    if (!this.props.value) {
      this.props.onChange
    }
  }

  render () {
    const {
      type,
      label,
      placeholder,
      name,
      value,
      required,
      fieldErrors,
      onChange
    } = this.props

    const cleanedName = this.props.name.replace(/[|&;$%@"'<>()+,]/g, "");

    return (
      <div
        className={classnames("form-group", {
          "has-error": fieldErrors && fieldErrors.length > 0
        })}
      >
        {label ? (
          <label
            className="control-label word-wrap"
            htmlFor={`input-${cleanedName}`}
          >
            {label}
            {required ? " *" : ""}
          </label>
        ) : null}
        <input id={`input-${cleanedName}`}
               placeholder={this.datetimeInputPlaceholder()}
               className='full-width' />
        {fieldErrors ? <span className="help-block">{fieldErrors.join(", ")}</span> : null}
      </div>
    )
  }

  datetimeInputPlaceholder = () => {
    switch (this.props.type) {
      case "date":
      case 2:
        return 'mm/dd/yyyy';
      case "time":
      case 11:
        return 'hh:mm';
      default:
        return 'mm/dd/yyyy hh:mm';
    }
  }
};

FormDateTime.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  fieldErrors: PropTypes.array,
  onChange: PropTypes.func
};

FormDateTime.defaultProps = {
  required: false
};

export default FormDateTime;
