import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-remarkable";

const FormDescription = ({
  description
}) => (
  <div>
    {description && (
      <div>
        <span className="grey-text line-break">
          <Markdown source={description} />
        </span>
        <hr />
      </div>
    )}
  </div>
);

FormDescription.propType = {
  description: PropTypes.string
};

export default FormDescription;
