import React from "react";
import PropTypes from "prop-types";

const FormHidden = props => {
  return (
    <div className="form-group">
       <input
         id={`input${props.name}`}
         type="hidden"
         name={props.name}
         value={props.value}
       />
    </div>
  );
}

FormHidden.propType = {
  value: PropTypes.string,
  name: PropTypes.string
};

export default FormHidden;
