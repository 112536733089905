import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Switch from "react-switch";

const FormToggle = ({
  type,
  labelTrue,
  labelFalse,
  name,
  value,
  errors,
  items,
  required,
  onChange
}) => (
  <Switch
    checked={value}
    onChange={onChange}
    onColor="#3a4592"
    offColor="#e33772"
    handleDiameter={26}
    uncheckedIcon={false}
    checkedIcon={false}
    uncheckedIcon={
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "white",
          paddingRight: 13
        }}
      >
        {labelFalse}
      </div>
    }
    checkedIcon={
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "white",
          paddingLeft: 4
        }}
      >
        {labelTrue}
      </div>
    }
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={30}
    width={120}
    className="react-switch"
    id="material-switch"
  />
);

FormToggle.propType = {
  labelTrue: PropTypes.string,
  labelFalse: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.array,
  value: PropTypes.string,
  items: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

FormToggle.defaultProps = {
  required: false
};

export default FormToggle;
