import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

const CheatsheetQuestionRow = ({
  cheatsheetQuestion,
  learnerContingencyIds,
  index,
  displayQuestions,
  addContingency,
  answerQuestion
}) => (
  <React.Fragment>
    {index != 0 &&
      <div className="row">
        <div className="col-md-12">
          <hr />
        </div>
      </div>
    }
    <div className="row">
      <div className="col-md-4">
        <div><strong>{index + 1}. {cheatsheetQuestion.title}</strong></div>
        {displayQuestions && (
          <span>
            <br />
            <button
              className="yes btn btn-default horizontal-buttons-right"
              onClick={() => answerQuestion(cheatsheetQuestion.id, true)}
            >
              Yes
            </button>
            <button
              className="yes btn btn-default horizontal-buttons-right"
              onClick={() => answerQuestion(cheatsheetQuestion.id, false)}
            >
              No
            </button>
          </span>
        )}
      </div>
      <div className="col-md-8">
        {cheatsheetQuestion.heading &&
          <h4>{cheatsheetQuestion.heading}</h4>
        }
        <ul className="cheatsheet-list">
          {cheatsheetQuestion.contingencies && cheatsheetQuestion.contingencies.map(contingency => (
            <li>
              {learnerContingencyIds.includes(contingency.id) ? (
                <div>
                  <a className="added-contingency" href={`/contingencies/${contingency.id}`} target="_blank">{contingency.full_translated_title}</a>
                  <span className="glyphicon glyphicon-ok pull-right added-contingency" title="Already Added"></span>
                </div>
              ) : (
                <div>
                  <a href={`/contingencies/${contingency.id}`} target="_blank">{contingency.full_translated_title}</a>
                  <a onClick={() => addContingency(contingency.id)}>
                    <span className="glyphicon glyphicon-plus pull-right" role="button" title="Add"></span>
                  </a>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </React.Fragment>
)

class CheatsheetHelper extends React.Component {
  state = {
    loading: false,
    done: false,
    cheatsheetQuestions: this.props.cheatsheetQuestions,
    learnerContingencyIds: this.props.learnerContingencyIds
  };

  render() {
    const {
      loading,
      fieldErrors,
      cheatsheetQuestions,
      learnerContingencyIds
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-4 text-center">
            <strong>Questions to Help Select Contingencies</strong>
          </div>
          <div className="col-md-8 text-center">
            <strong>TYPES OF PROGRAMMING</strong>
            <br />
            Suggested Contingencies
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>

        {cheatsheetQuestions && cheatsheetQuestions.filter(cheatsheetQuestion => cheatsheetQuestion.display_question == true).map((cheatsheetQuestion, index) => (
          <CheatsheetQuestionRow
            key={index}
            index={index}
            cheatsheetQuestion={cheatsheetQuestion}
            learnerContingencyIds={learnerContingencyIds}
            addContingency={this.addContingency}
            answerQuestion={this.answerQuestion}
            displayQuestions={index < (cheatsheetQuestions.length - 1)}
          />
        ))}
      </div>
    );
  }

  handlerChangeField = (newValue, key, checked=null) => {
    const cheatsheetQuestions = this.state.cheatsheetQuestions;

    if (key == "contingency_ids") {
      cheatsheetQuestions[key] = this.toggleContigencyId(newValue, checked);
    } else {
      cheatsheetQuestions[key] = newValue;
    }

    this.setState({ cheatsheetQuestions: cheatsheetQuestions, fieldErrors: {} });
  };

  addContingency = (contingencyId) => {
    var that = this;
    const formData = new FormData();
    var learnerContingencyIds = this.state.learnerContingencyIds;
    learnerContingencyIds.push(contingencyId);

    formData.append(`learner_contingency[contingency_id]`, contingencyId);
    formData.append(`learner_contingency[learner_id]`, this.props.learnerId);
    formData.append(`learner_contingency[learner_folder_id]`, this.props.learnerFolderId);

    let config = {
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }

    axios.post(
      "/learner_contingencies/",
      formData,
      config
    ).then(request => {
        this.setState({
          learnerContingencyIds: learnerContingencyIds,
          done: true,
          loading: false
        });
      })
      .catch(function(error) {
        that.processRailsErrors(error.response.data);
        that.setState({ loading: false, done: false });
      });
  };

  answerQuestion = (cheatsheetQuestionId, answer) => {
    var cheatsheetQuestions = this.state.cheatsheetQuestions;
    var questionIndex = cheatsheetQuestions.findIndex(cheatsheetQuestion => cheatsheetQuestion.id == cheatsheetQuestionId);
    cheatsheetQuestions[questionIndex + 1].display_question = true;
    this.setState({ cheatsheetQuestions: cheatsheetQuestions, fieldErrors: {} });
  };

  processRailsErrors = (errorFields) => {
    console.log("errorFields", errorFields)
    const fieldErrors = this.state.fieldErrors;
    Object.keys(errorFields).forEach(errorFieldKey => {
      fieldErrors[errorFieldKey] = errorFields[errorFieldKey];
    });
    this.setState({ fieldErrors: fieldErrors });
  };
}

CheatsheetHelper.propTypes = {
  formType: PropTypes.string,
  formName: PropTypes.string,
  formUrl: PropTypes.string
};

export default CheatsheetHelper;
